<template>
  <article>

    <InformationListAccordion
      v-if="category.whole_information_list && category.whole_information_list.length"
      :informations="category.whole_information_list"
      :content-class="'whole-information'"
      title="重要なお知らせ"
      noticeText="重要"
    />

    <category-content-section
      :data="category"
      :artist_page_url="artist_page_url"
      :event_page_url="event_page_url"
      :current-artist="currentArtist"
    />

    <div v-if="category.news && category.news.length" class="content__inner">
      <InformationListAccordion
        :informations="category.news"
        :content-class="'category-main__news'"
        title="お知らせ"
        noticeText="INFO"
      />
    </div>

    <section class="content pt-50 bg-white">
      <div class="content__inner pb-50">

        <dl class="category-dl mb-50">
          <dt class="category-dl__name">
            <div ref="categoryMain" class="category-dl__name__container">
              <keep-proportion-content
                :proportion-target="() => $refs.categoryMain"
                :style="{ color: category.category_name_text_color }"
                :content="category.category_name_top_page"
                :p-width="607"
                :gap="0"
                :gap-sp="0"
                blank-to-br
              />
            </div>
          </dt>
          <dd class="category-dl__price">
            <div class="price-title">{{ $t('category.selling_price') }}</div>
            <div class="price-detail">
              <p>¥{{ category.product_standard_total_amount_with_tax }}<span class="small">({{ $t('category.tax_included') }})/{{category.purchasable_unit}}{{ unitName }}</span></p>
            </div>
          </dd>
        </dl>

        <!--    #### スペック      -->
        <div v-if="category.product_standard_display_attribute" ref="categorySpec" class="category-spec mb-40">
          <Headline :title="$t('category.spec')"/>
          <keep-proportion-content
            :proportion-target="() => $refs.categorySpec"
            :content="category.product_standard_display_attribute"
            :p-sp-width="380"
            :gap="0"
            :gap-sp="0"
          />

          <vue-slick-carousel
            v-if="Array.isArray(category.product_standard_sample_images) && category.product_standard_sample_images.length"
            ref="productStandardCarousel"
            class="category__carousel mt-30"
            v-bind="carouselSetting"
          >
            <div
              v-for="(product_standard_sample_image, index) in category.product_standard_sample_images"
              :key="`product_standard_carousel_${index}`"
              class="category__carousel__image-wrapper"
              @click="onCarouselImageClick(product_standard_sample_image,'product_standard_sample_images', index)"
            >
              <img
                class="category__carousel__image"
                :src="product_standard_sample_image.image_url"
                draggable="false"
                alt=""
              />
            </div>
          </vue-slick-carousel>

        </div>

        <!--    #### コメント     -->
        <div v-if="category.category_main_text" ref="categoryComment" class="content__inner--editor">
          <Headline :title="$t('category.comment')"/>
          <keep-proportion-content
            :proportion-target="() => $refs.categoryComment"
            :content="category.category_main_text"
            :p-sp-width="380"
            :gap="0"
            :gap-sp="0"
          />
        </div>
      </div>

      <div :class="[ 'bg-gray', 'category', { 'no-top-padding': hasRandomOrGachaBanner }]">

        <!--    #### ランダムバナー画像      -->
        <section
          v-if="hasRandomOrGachaBanner"
          :style="{ backgroundColor : category.random_banner_background_color}"
          class="content category-random-banner_wrapper mb-40"
        >
          <div class="content__inner">
            <div class="">
              <div class="">

                <div v-if="true">
                  <Headline :title="category.random_banner_title" no-bg-color />
                </div>

                <img
                  v-if="category.random_banner_image_url"
                  :src="category.random_banner_image_url"
                  height="auto"
                />

              </div>
            </div>

          </div>

        </section>


        <div class="content__inner">

          <category-cart-status-bar
            v-if="!isRandomOrGacha"
            :class="[!isMobile ? '' : 'category-dl__space-bottom']"
            :total="realTotalQty"
            :amount="sub_total"
            :set-total="sets_amount"
            :is_mobile="isMobile"
            @to-cart="onToCart"
          />

          <div v-if="displayRestoreCart" class="category-pre-cart-actions">
            <pre-cart
              show
              :on-publish-key="onPublishKey"
              :on-restore-cart="onRestoreCart"
            />
          </div>

          <category-pagination
            v-if="!isRandomOrGacha"
            :meta="saleProducts.meta"
            :total="saleProducts.data.length"
            @page="(page) => handleOnPage(page)"
          />

          <div v-if="justQtyAsSetAmount" class="mb-30 mt-10 large bold al-c set-sell__annotation">
            <template v-if="total_qty > 0">
              <p>{{ total_qty }}{{ unitName }}の商品を選択しました。</p>
              <p>{{ $t('category.proceed_to_card') }}</p>
            </template>
            <template v-else>
              <p>セット数をお選びください。</p>
            </template>
          </div>

          <p v-else class="mb-30 mt-10 large bold al-c set-sell__annotation c-red">
            {{ suggest_qty_amount }}{{ unitName }}まで選択して、カートに進んでください。
          </p>

          <category-page-description v-if="!isRandomOrGacha" :is-phone="isPhone" />

          <template v-if="!isRandomOrGacha">
            <div class="category__list mb-50">
              <category-list-item
                v-for="(sale_product) in saleProducts.data"
                :key="sale_product.id"
                :sale_product="sale_product"
                :is-in-cart="productInCartExists(sale_product.id)"
                :has-caption="saleProducts.meta.has_caption"
                @click-image="onMultiPurchaseClick(sale_product)"
                @to-multi-purchase="onMultiPurchaseClick(sale_product)"
                @add-cart="onAddCart({sale_product})"
              />
            </div>
          </template>

          <category-gacha-item
            v-if="isRandomOrGacha && saleProducts.data.length"
            v-model="_saleProductPurchasePopup"
            :sale_product="saleProducts.data[0]"
            :is-in-cart="productInCartExists(saleProducts.data[0].id)"
            :is-phone="isPhone"
            :is-extra-small-device="isExtraSmallDevice"
            @input="(_data) => onUpdateCart({ sale_product: saleProducts.data[0], qty: _data.qty })"
            @add-to-cart="(_data) => onAddCart({ sale_product: saleProducts.data[0], qty:_data.qty })"
          />

          <category-pagination
            v-if="!isRandomOrGacha"
            :meta="saleProducts.meta"
            :total="saleProducts.data.length"
            @page="(page) => handleOnPage(page)"
          />

          <category-cart-status-bar
            :class="{'category-dl__space-bottom2':isMobile}"
            :total="realTotalQty"
            :amount="sub_total"
            :set-total="sets_amount"
            :is_mobile="isMobile"
            @to-cart="onToCart"
          />

          <div v-if="displayRestoreCart" class="category-pre-cart-actions">
            <pre-cart
              show
              :on-publish-key="onPublishKey"
              :on-restore-cart="onRestoreCart"
            />
          </div>

        </div>
      </div>
    </section>

    <ChildUnderEvent
      v-if="category.event_id && hasRelatedProduct"
      :event_id="category.event_id"
      :except_category_id="except_category_id"
      :filters="filters"
      :product-standard="productStandard"
      :sale-products="relatedProducts"
      :title="label.RELATION_EN"
      :subtitle="label.RELATION_JP"
      @filter-change="handleFilterChange"
    />

    <EventList
      v-if="other_events.length"
      :event-link="{ name: 'event.page' }"
      :events="other_events"
      :is_mobile="isMobile"
      :title="label.OTHER_EVENT_EN"
      :subtitle="label.OTHER_EVENT_JP"
    />

    <category-select-quantity-modal
      ref="popupPurchase"
      v-model="_saleProductPurchasePopup"
      :is-phone="isPhone"
      :is-extra-small-device="isExtraSmallDevice"
      :is-in-cart="productInCartExists(sale_product_purchase_popup.id)"
      :is-set="isRandomOrGacha"
      @close="handleOnClose"
      @next="handleOnNext"
      @prev="handleOnPrevious"
      @add-to-cart="(_data) => onAddCart({ sale_product: selectedSaleProduct, qty:_data.qty })"
      @image-loaded="onLoadPopupImage"
      @input="(_data) => onUpdateCart({ sale_product: selectedSaleProduct, qty: _data.qty })"
    />

    <CarouselSelectModal
      ref="carouselPopup"
      :popup-image="this.carouselPopupImage"
      @close="handleOnCarouselPopupClose"
      @next="handleOnCarouselPopupNext"
      @prev="handleOnCarouselPopupPrevious"
    />

    <bundled-category-footer-menu
      v-if="isBundledCategory && cartCategory"
      :cart-data="cartCategory"
      :to-cart="toCart"
    />


    <!--  LDH対応 LDH終了後に要削除  -->
    <section v-if="isAuditionMode" class="content bg-gray" style="background-color: rgb(229, 0, 17);">
      <div class="content__inner top-banner">
        <div class="top-banner__item  box-shadow">
          <a href="https://www.exiletribestation.jp/news/436" rel="noopener noreferrer">
            <img src="https://img.bromide.jp/public/artist_banner/rpTWklfZ3WmCDuqfBjZaXa7E2SgIMsAZPX4yz3uD/tribe_news.png?t=20220722110254" alt="">
          </a>
        </div>
        <div class="top-banner__item  box-shadow">
          <a href="https://bromide.jp/bot20220721" rel="noopener noreferrer">
            <img src="https://img.bromide.jp/public/artist_banner/USWKsdbdI0fA3pkYmOhOTNfYNNkNbH1MWOFeu9ck/day1.png?t=20220722110254" alt="">
          </a>
        </div>
        <div class="top-banner__item  box-shadow">
          <a href="https://bromide.jp/bot20220722" rel="noopener noreferrer">
            <img src="https://img.bromide.jp/public/artist_banner/5PybwQjScIhdEkyS30zmHum6TqZD5NEC7szA0a4N/day2.png?t=20220722110254" alt="">
          </a>
        </div>
        <div class="top-banner__item  box-shadow">
          <a href="https://bromide.jp/bot20220723" rel="noopener noreferrer">
            <img src="https://img.bromide.jp/public/artist_banner/SWG4Jv2alL7B944k8cfE1yxu6WMxkJ0aMSUczNyj/day3.png?t=20220722110254" alt="">
          </a>
        </div>
      </div>
    </section>
    <!--  LDH対応 LDH終了後に要削除ここまで  -->


  </article>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import KeepProportionContent from '../base/KeepProportionContent'
import auditionModeSwitcherMixin from "../../helpers/auditionModeSwitcher.mixin";

export default {
  name: "CategoryTemplate",
  components: {
    PreCart: () => import('@components/cart/PreCart/PreCart'),
    BundledCategoryFooterMenu: () => import('@components/category/BundledCategoryFooterMenu/BundledCategoryFooterMenu'),
    VueSlickCarousel: () => import('vue-slick-carousel'),
    KeepProportionContent,
    InformationListAccordion: () => import('@components/base/InformationListAccordion'),
    CategoryContentSection: () => import('@components/category/CategoryContentSection'),
    Headline: () => import('@components/base/Headline'),
    CategoryCartStatusBar: () => import('@components/category/CategoryCartStatusBar'),
    CategoryPagination: () => import('@components/category/CategoryPagination'),
    CategoryPageDescription: () => import('@components/category/CategoryPageDescription'),
    CategoryListItem: () => import('@components/category/CategoryListItem'),
    CategoryGachaItem: () => import('@components/category/CategoryGachaItem'),
    ChildUnderEvent: () => import('@pages/event/ChildUnderEvent'),
    EventList: () => import('@components/event/EventList'),
    CategorySelectQuantityModal: () => import('@components/category/CategorySelectQuantityModal'),
    CarouselSelectModal: () => import('@components/category/CarouselSelectModal'),
  },
  mixins: [ auditionModeSwitcherMixin ],
  model: {
    prop: 'sale_product_purchase_popup',
    event: 'update'
  },
  data() {
    return {
      carouselPopupTarget: '',
      carouselPopupImage: {}
    }
  },
  props: {
    isMobile:{
      type: Boolean,
      default: false
    },

    category: {
      type: Object,
      required: true
    },
    artist_page_url: {
      type: String,
      default: ''
    },
    event_page_url: {
      type: String,
      default: ''
    },

    total_qty: {},
    realTotalQty: {
      type: [String, Number],
      default: 0
    },
    sub_total: {},
    sets_amount: {},

    justQtyAsSetAmount: {
      type: Boolean,
      required: true
    },

    unitName: {
      type: String,
      required: true
    },

    suggest_qty_amount: {},

    isPhone: {
      type: Boolean,
      default: false
    },
    isExtraSmallDevice: {
      type: Boolean,
      default: false
    },

    cartCategory: {
      type: [String, Number, Object],
    },

    except_category_id: {
      type: String,
    },

    filters: {
      type: Object
    },
    productStandard: {
      type: Object
    },
    saleProducts: {
      type: Object
    },

    other_events: {

    },

    sale_product_purchase_popup: {
      type: Object
    },

    selectedSaleProduct: {

    },
    relatedProducts: {
      type: Array,
      default: () => ([])
    },

    carouselSetting: {
      type: Object,
      default: () => {}
    },

    toCart: {
      type: [ Object, String ],
      default: ''
    },

    onPublishKey: {
      type: Function,
      default: () => {}
    },

    onRestoreCart: {
      type: Function,
      default: () => {}
    },

    displayRestoreCart: {
      type: Boolean,
      default: false
    },
    currentArtist: {
      type: Object,
      required: true
    }
  },
  created() {
    window.scrollTo(0, 0)
  },
  computed: {
    isBundledCategory() {
      return this.category.print_sale_plan_type === 'bundled'
    },
    _saleProductPurchasePopup: {
      get() {
        return this.sale_product_purchase_popup
      },
      set(value) {
        this.$emit('update-popup', value)
      }
    },
    hasRandomOrGachaBanner() {
      return !!(this.category.random_banner_title || this.category.random_banner_image_url)
    },
    isRandomOrGacha() {
      return this.category.print_sale_plan_type === 'bundled_random' || this.category.print_sale_plan_type === 'gacha'
    },
    hasRelatedProduct() {
      if (!this.relatedProducts) return false
      return Object.keys(this.relatedProducts).length
    },
    hasProductStandard() {
      if (!this.productStandard) return false
      const { normal, printed } = this.productStandard
      return (Array.isArray(normal) && normal.length) || (Array.isArray(printed) && printed.length)
    },
  },
  methods: {
    handleOnPage(page) {
      this.$emit('page', page)
    },
    productInCartExists(id) {
      return this.cartCategory && this.cartCategory.cart_products.some(el => el.sale_product_id == id)
    },
    onMultiPurchaseClick(sale_product) {
      this.$emit('multi-purchase-click', sale_product)
    },
    onAddCart({ sale_product, qty = 1}) {
      this.$emit('add-to-cart', {
        sale_product,
        qty
      })
    },
    onToCart() {
      this.$emit('to-cart')
    },
    handleFilterChange({key, value}) {
      this.$emit('filter-change', { key, value})
    },

    handleOnClose() {
      this.$emit('close')
    },
    handleOnNext() {
      this.$emit('next')
    },
    handleOnPrevious() {
      this.$emit('prev')
    },
    onLoadPopupImage() {
      this.$emit('image-loaded')
    },
    onUpdateCart({ sale_product, qty = 1 }) {
      this.$emit('input', {
        sale_product,
        qty
      })
    },
    onCarouselImageClick(product, target, index) {
      this.carouselPopupTarget = target
      this.setCarouselPopupImage(product, index)
    },
    setCarouselPopupImage(product, index) {
      if (this.category && this.category[this.carouselPopupTarget]) {
        const popupTargetImagesLength = this.category[this.carouselPopupTarget].length
        this.carouselPopupImage = {
          id: product.id,
          image_url: product.image_url,
          index,
          first: index === 0,
          last: index === (popupTargetImagesLength - 1)
        }
        this.$refs.carouselPopup.show()
      } else {
        this.handleOnCarouselPopupClose()
      }
    },
    handleOnCarouselPopupClose() {
      this.carouselPopupTarget = ''
      this.carouselPopupImage = {}
      this.$refs.carouselPopup.hide()
    },
    handleOnCarouselPopupNext(popupImage) {
      if (!popupImage.last) {
        const nextPopupImage = this.category[this.carouselPopupTarget][popupImage.index + 1]
        this.setCarouselPopupImage(nextPopupImage, popupImage.index + 1)
      } else {
        this.handleOnCarouselPopupClose()
      }
    },
    handleOnCarouselPopupPrevious(popupImage) {
      if (!popupImage.first) {
        const prevPopupImage = this.category[this.carouselPopupTarget][popupImage.index - 1]
        this.setCarouselPopupImage(prevPopupImage, popupImage.index - 1)
      } else {
        this.handleOnCarouselPopupClose()
      }
    },
  }
}
</script>
